<figure
	class="embed-item embed-item-video"
	bind:this="{el}"
	class:was-loaded="{wasLoaded}"
	class:has-caption="{showCaption}"
	data-embed-id="{embedId}"
>
	<div
		class="embed-container"
		style="padding-bottom:{ ratio * 100 }%"
	>
		<div class="embed-content">
			{#if canShow}
			<video
				class="embed-video"
				src="{embedURL}"
				bind:this="{videoEl}"
				on:canplay="{canPlayChanged}"
				on:loadeddata="{loadedData}"
				on:pause="{videoPausedByUser}"
				on:play="{videoStartedByUser}"
				on:click="{videoClicked}"
				poster="{posterSrc}"
				webkitallowfullscreen
				mozallowfullscreen
				allowfullscreen
				controls
			></video>
			{/if}
		</div>
	</div>
	{#if showCaption}
	<figcaption class="embed-caption">
		<div class="content-center">{embedCaption}</div>
	</figcaption>
	{/if}
</figure>

<script>
import { onMount, onDestroy } from 'svelte';
import { activeProjectIds, visibleProjectIds } from './stores/projects.js';
import {
	requestedEmbedIds,
	embedLoaded,
	wasEmbedLoaded,
	loadedEmbedIds,
	currentlyPlayingEmbedId,
	embedWasPaused,
	embedWasStarted
} from './stores/embed.js';

export let linkEl = null;
export let embedId = null;
export let projectId = null;

let el = null;
let videoEl = null;

let isVisible = false;
let isObserving = false;
let isPlaying = false;

const observerOptions = { threshold: [ 0, 0.2, 0.4, 0.6, 0.8, 1 ] };
const observer = new IntersectionObserver( observerChanged, observerOptions );

$ : isProjectOpen = $activeProjectIds.includes( projectId );
$ : isProjectVisible = $visibleProjectIds.includes( projectId );
$ : canShow = projectId ? isProjectOpen && isProjectVisible : true;

$ : embedURL = linkEl ? linkEl.getAttribute( 'href' ) : '';
$ : embedCaption = linkEl ? linkEl.textContent : null;
$ : showCaption = linkEl.getAttribute( 'data-hide-caption' ) ? false : true;
$ : settings = Object.assign( { }, linkEl.dataset || { } );
$ : wasLoaded = $loadedEmbedIds.includes( embedId );
$ : ratio = settings.embedHeight / settings.embedWidth;
$ : activeEmbedId = currentlyPlayingEmbedId;
$ : posterSrc = linkEl.getAttribute( 'data-video-poster' );

$ : {
	if ( ! isObserving && ! wasLoaded && canShow ) {
		startObserving();
	}
}

onMount( () => {
	linkEl.classList.add( 'was-instantiated' );

	document.addEventListener( 'visibilitychange', visibilityChanged, false );

	if ( projectId ) {
		activeProjectIds.subscribe( () => {
			if ( isProjectOpen ) {
				startObserving();
			} else {
				stopObserving();
				videoPausedBySystem();
			}
		} );
	} else {
		startObserving();
	}

	currentlyPlayingEmbedId.subscribe( newActiveEmbedId => {
		if ( newActiveEmbedId !== embedId ) {
			videoPausedBySystem();
		}
	} );
} );

onDestroy( () => {
	document.removeEventListener( 'visibilitychange', visibilityChanged, false );
	stopObserving();
} );

function startObserving () {
	if ( ! isObserving ) {
		observer.observe( el );
		isObserving = true;
	}
}

function stopObserving () {
	if ( isObserving ) {
		observer.unobserve( el );
		isObserving = false;
	}
}

function observerChanged ( changes ) {
	changes.forEach( change => {
		const el = change.target;
		const visibilityRatio = change.intersectionRatio;

		if ( visibilityRatio < 0.6 && isPlaying ) {
			videoPausedBySystem();
		}
	} );
}

function visibilityChanged () {
	if ( document.hidden ) {
		videoPausedBySystem();
	}
}

function canPlayChanged ( event ) {
	embedLoaded( embedId );
}

function loadedData ( event ) {
	embedLoaded( embedId );
}

function videoClicked () {
	if ( isPlaying ) {
		videoPausedByUser();
	}
}

function videoStartedByUser () {
	isPlaying = true;
	embedWasStarted( embedId );
}

function videoPausedByUser () {
	isPlaying = false;
	embedWasPaused();
}


function videoPausedBySystem () {
	if (
		isPlaying &&
		videoEl
	) {
		videoEl.pause()
		isPlaying = false;
		embedWasPaused();
	}
}

</script>