import { writable, derived, get } from 'svelte/store';
import { getScrollPos } from '../util/pos.js';
import { activeProjectIds } from './projects.js';

let scrollAnimationFrameId = null;
let resizeAnimationFrameId = null;

export const fasterScrollPos = writable( 0 );
export const scrollPos = writable( 0 );
export const windowSize = writable( getWindwSize() );

export function init () {
	window.addEventListener( 'scroll', updateScroll );
	window.addEventListener( 'resize', updateSize );
	activeProjectIds.subscribe( updateScroll );
	updateScroll();
	updateSize();
}

function updateScroll () {
	const pos = getScrollPos();
	fasterScrollPos.set( pos );
	
	cancelAnimationFrame( scrollAnimationFrameId );

	scrollAnimationFrameId = requestAnimationFrame( () => {
		scrollPos.set( pos );
	} );
}

function updateSize () {
	cancelAnimationFrame( resizeAnimationFrameId );
	resizeAnimationFrameId = requestAnimationFrame( () => {
		windowSize.set( getWindwSize() );
	} );
}

function getWindwSize () {
	return {
		width: window.innerWidth,
		height: window.innerHeight
	};
}