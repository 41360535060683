<script>
import { onMount, tick } from 'svelte';
import { ready, isSafari } from './util/browser.js';
import { activeProjectIds, visibleProjectIds } from './stores/projects.js';
import Splitting from "splitting";

export let textEl, id;

const canSplitText = ! isSafari && window.innerWidth > 1200;

// unobserve if project is closed / not visible
// TODO: delay text contents that follow each other?

const originalNodes = [ ];

const observerOptions = { threshold: [ 0, 0.33, 0.66, 1 ], rootMargin: '30px 0px -10px 0px' };

let isObserving = false;
let resizeTimeoutId = null;
let paragraphEls = [ ];
let lines = [ ];
let chars = { };

$ : isProjectOpen = $activeProjectIds.includes( id );
$ : isProjectVisible = $visibleProjectIds.includes( id );
$ : canObserve = isProjectOpen && isProjectVisible;
$ : {
	if ( canObserve ) {
		startObservation();
	} else {
		stopObservation();
	}
}

onMount( () => {
	paragraphEls = Array.from( textEl.querySelectorAll( 'p, ul, h1, h2' ) );

	paragraphEls.forEach( ( paragraphEl, index ) => {
		paragraphEl.style.setProperty('--paragraph-index', index );
		paragraphEl.style.setProperty('--paragraphs-total', paragraphEls.length );
		originalNodes[index] = paragraphEl.cloneNode( true );
	} );

	if ( canObserve ) {
		startObservation();
	}

	window.addEventListener( 'resize', resized );
} );

function startObservation ( force = false ) {
	if (
		( canSplitText ) &&
		(
			( canObserve && ! isObserving ) ||
			( canObserve && force )
		)
	) {
		isObserving = true;

		lines.forEach( line => line.destroy( force ) );
		lines = [ ];

		paragraphEls.forEach( ( paragraphEl, index ) => {
			const newPragraph = originalNodes[index].cloneNode( true );

			if ( paragraphEl && newPragraph ) {
				paragraphEl.replaceWith( newPragraph );
				paragraphEls[index] = newPragraph;

				const by = 'lines';
				// const by =  paragraphEl.classList.contains( 'subheadline' ) ? 'chars' : 'lines';
				const results = Splitting( { target: newPragraph, by } );

				results.forEach( result => {
					if ( result.lines ) {
						result.lines.forEach( line => {
							lines.push( new Line( { items: line }) );
						} );
					}
				} );	
			}
		} );
	}
}

function stopObservation() {
	if ( ! canObserve && isObserving ) {
		isObserving = false;

		lines.forEach( line => line.destroy() );
		lines = [ ];
	}
}

function resized () {
	clearTimeout( resizeTimeoutId );
	
	resizeTimeoutId = setTimeout( () => {
		startObservation( true );
	}, 1200 );
}

class Line {
	constructor ( params = { } ) {
		this._visible = false;
		this.items = params.items || [ ];
		this.observer = null;
		this.observationTargetEl = null

		this.items.forEach( ( itemEl, index ) => {
			itemEl.style.setProperty('--word-in-line-index', index );
			
			if ( itemEl.parentNode.matches( 'a' ) ) {
				itemEl.parentNode.classList[this._visible ? 'add' : 'remove']( 'is-visible' );
			}
		} );
		
		this.visible = false;


		this.observe( observerOptions );
	}

	observe ( observerOptions ) {
		this.unobserve();
		
		if ( this.items.length ) {
			this.observationTargetEl = this.items[0];
			this.observer = new IntersectionObserver( changes => this.observerChanged( changes ), observerOptions );
			this.observer.observe( this.observationTargetEl );
		}
	}

	unobserve () {
		if ( this.observationTargetEl ) {
			this.observer.unobserve( this.observationTargetEl );
		}
		
		this.observationTargetEl = null;
		this.observer = null;
	}

	set isVisible ( newVisible ) {
		if ( newVisible !== this._visible ) {
			this._visible = !! newVisible;

			this.items.forEach( itemEl => {
				tick()
					.then( () => {
						itemEl.classList[this._visible ? 'add' : 'remove']( 'is-visible' );
						
						if ( itemEl.parentNode.matches( 'a' ) ) {
							itemEl.parentNode.classList[this._visible ? 'add' : 'remove']( 'is-visible' );
						}
					} );
			} );
		}
	}

	get isVisible () {
		return this._visible;
	}

	observerChanged ( changes ) {
		changes.forEach( change => {
			const ratio = change.intersectionRatio;
			this.isVisible = ratio > 0.66;
		} );
	}

	destroy () {
		this.unobserve();
		this.visible = false;
	}
}

</script>