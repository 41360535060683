import { writable, derived, get } from 'svelte/store';
import SmoothScroll from 'smooth-scroll';
import { scrollToEl } from '../util/scrollTo.js';
import { sort } from '../util/array.js';
import { mapRange } from '../util/math.js';
import { ready } from '../util/browser.js';

const currentScroller = new SmoothScroll();

export const projectEls = writable( { } );
export const activeProjectIds = writable( [ ] );
export const projectVisibilities = writable( { } );

export const lastOpenedProjectId = writable( '' );
export const lastClosedProjectId = writable( '' );
export const lastChangedProjectId = writable( '' );

export const projectIds = derived(
	projectEls,
	$projectEls => Object.keys( $projectEls )
);

export const visibleProjectIds = derived(
	[ projectIds, projectVisibilities],
	( [ $projectIds, $projectVisibilities ] ) => {
		const visibleIds = $projectIds.filter( id => $projectVisibilities[id] > 0 );
		const sortedIds = sort( visibleIds, ( a, b ) => $projectVisibilities[b] - $projectVisibilities[a] );

		return sortedIds;
	}
);

export const fullyVisibleProjectIds = derived(
	[ projectIds, projectVisibilities],
	( [ $projectIds, $projectVisibilities ] ) => {
		return Object.keys( $projectVisibilities ).filter( key => $projectVisibilities[key] === 1 )
	}
);

export const wereProjectsInitialized = derived( projectIds, projectIds => projectIds.length > 0 );

let wasHashChecked = false;

wereProjectsInitialized.subscribe( wereLoaded => {
	if ( wereLoaded && ! wasHashChecked ) {
		checkHash();
	}
} )

window.addEventListener( 'hashchange', checkHash );

function checkHash () {
	wasHashChecked = true;
	const id = ( location.hash || '' ).replace( '#', '' );

	navigateToProject( id );
}

export function loadProjects () {
	const newProjectEls = Array
		.from( document.querySelectorAll( '.project' ) )
		.reduce( ( result, projectEl ) => {
			const id = projectEl.getAttribute( 'id' );
			result[id] = projectEl;
			return result;
		}, { } );

	projectEls.set( newProjectEls );
}

export function navigateToProject ( id, preventPush = false ) {
	if ( doesProjectExist( id ) ) {
		return scrollToProject( id )
			.then( () => {
				if ( ! preventPush ) {
					history.pushState( null, null, `#${id}` );
				}

				openProject( id );
			} );
	} else {
		return Promise.resolve();
	}
}

export function openProject ( id ) {
	if ( ! isProjectOpen( id ) ) {
		lastClosedProjectId.set( '' );
		lastOpenedProjectId.set( id );
		lastChangedProjectId.set( id + '|' + Date.now() );

		activeProjectIds.set( [ ... get( activeProjectIds ), id ] );
	}
}

export function closeProject ( id ) {
	if ( isProjectOpen( id ) ) {
		const index = get( activeProjectIds ).indexOf( id );

		if ( index > -1 ) {
			lastClosedProjectId.set( id );
			lastOpenedProjectId.set( '' );
			lastChangedProjectId.set( id + '|' + Date.now() );

			const newProjectIds = get( activeProjectIds ).slice( 0 );
			newProjectIds.splice( index, 1 );
			activeProjectIds.set( newProjectIds );

		}
	}
}

export function setProjectVisibility ( id, ratio ) {
	projectVisibilities.update( visibilities => {
		visibilities[id] = ratio;
		return visibilities;
	} );
}

export function isProjectOpen ( id ) {
	return get( activeProjectIds ).includes( id );
}

export function isProjectVisible ( id ) {
	return get( visibleProjectIds ).includes( id );
}

export function isProjectFullyVisible ( id ) {
	return get( fullyVisibleProjectIds ).includes( id );
}

export function isProjectMostVisible ( id ) {
	return get( visibleProjectIds )[0] === id;
}

export function doesProjectExist ( id ) {
	return get( projectIds ).includes( id );
}

export function getProjectElById ( id ) {
	return get( projectEls )[id];
}

export function scrollToProject ( id ) {
	const projectEl = getProjectElById( id );
	
	if ( projectEl ) {
		const projectWrapperEl = projectEl.closest( '.project-wrapper' );

		if ( projectWrapperEl ) {
			return scrollToElement( projectWrapperEl );
		} else {
			return scrollToElement( projectEl );
		}
	}

	return Promise.resolve();
}

export function scrollToElement ( el ) {
	const speed = mapRange( window.innerWidth, 680, 1400, 4000, 1050, true );
	return scrollToEl( el, { speed } );
}