<button class="btn open-btn animated-btn" on:click="{open}" disabled="{isOpen}">
	<span class="btn-text">explore</span>
	<span class="btn-hover-text">explore</span>
</button>

<button class="btn close-btn" on:click="{close}" disabled="{!isOpen}">
	<span>×</span>
</button>

<style>

</style>

<script>
	import { onMount, tick } from 'svelte';
	import {
		activeProjectIds,
		visibleProjectIds,
		navigateToProject,
		closeProject,
		isProjectOpen,
		isProjectVisible,
		isProjectFullyVisible,
		isProjectMostVisible,
		setProjectVisibility,
		lastClosedProjectId,
		lastOpenedProjectId
	} from './stores/projects.js';
	// import { accordion } from './transitions/accordion.js';
	import { clamp } from './util/math.js';
	import { loadStyleSheet } from './util/css.js';

	export let id, projectEl, contentWrapperEl, contentEl;

	$ : isOpen = $activeProjectIds.includes( id );
	
	let visibleAnimationFrame = null;
	let wasCSSLoaded = false;
	let isHeaderClickBlocked = false;

	const observerOptions = { threshold: [ 0, 0.2, 0.4, 0.6, 0.8, 1 ] };
	const observer = new IntersectionObserver( observerChanged, observerOptions );

	const cssOserverOptions = { threshold: [ 0, 0.1, 0.2, 0.6, 0.8, 1 ], rootMargin: '200px 0px 200px 0px' };
	let cssObserver = new IntersectionObserver( cssObserverChanged, cssOserverOptions );

	onMount( () => {
		projectEl.setAttribute( 'data-id', id );

		const headerEl = projectEl.querySelector( '.project-header' );
		const projectWrapperEl = headerEl.closest( '.project-wrapper' );
		// observer.observe( headerEl );
		observer.observe( projectWrapperEl );
		cssObserver.observe( projectWrapperEl );
		setProjectVisibility( id, 0 );

		headerEl.addEventListener( 'click', headerClicked );
	} );

	function open () {
		navigateToProject( id );
	}

	function close () {
		closeProject( id );
	}

	function headerClicked () {
		setTimeout( () => {
			if (
				! isOpen &&
				! isHeaderClickBlocked
			) {
				open();
			}
		}, 30 );
	}

	activeProjectIds.subscribe( ids => {
		tick()
			.then( () => {
				const speed = 1750; // pxPerSec
				const contentHeight = contentEl.offsetHeight * 1.05;
				const duration = clamp( contentHeight / ( speed / 1000 ), 450, 1200 );

				if ( $lastOpenedProjectId === id ) {
					contentWrapperEl.style.transitionDuration = duration.toFixed(2) + 'ms';

					// NO! JUST IF IT WAS OPEN BEFORE!
					projectEl.classList.add( 'is-opening' );
					projectEl.classList.add( 'is-open' );
					contentWrapperEl.style.maxHeight = contentHeight + 'px';
					isHeaderClickBlocked = true;

					setTimeout( () => {
						projectEl.classList.remove( 'is-opening' );
						isHeaderClickBlocked = false;
					}, duration );
				}

				if ( $lastClosedProjectId === id ) {
					isHeaderClickBlocked = true;
					projectEl.classList.remove( 'is-open' );
					projectEl.classList.add( 'is-closing' );
					contentWrapperEl.style.maxHeight = null;

					setTimeout( () => {
						projectEl.classList.remove( 'is-closing' );
						isHeaderClickBlocked = false;
					}, duration );
				}
			} );
	} );

	visibleProjectIds.subscribe( ids => {
		cancelAnimationFrame( visibleAnimationFrame );
		
		visibleAnimationFrame = requestAnimationFrame( () => {
			if ( isProjectVisible( id ) ) {
				projectEl.classList.add( 'is-visible' );
			} else {
				projectEl.classList.remove( 'is-visible' );
			}

			if ( isProjectMostVisible( id ) ) {
				projectEl.classList.add( 'is-most-visible' );
			} else {
				projectEl.classList.remove( 'is-most-visible' );
			}

			if ( isProjectFullyVisible( id ) ) {
				projectEl.classList.add( 'is-fully-visible' );
			} else {
				projectEl.classList.remove( 'is-fully-visible' );
			}
		} );
	} );

	function observerChanged ( changes ) {
		changes.forEach( change => {
			const ratio = change.intersectionRatio;
				
			setProjectVisibility( id, ratio );

			if ( ratio > 0 && ! wasCSSLoaded ) {
				loadCSS();
			}
		} );
	}

	function cssObserverChanged ( changes ) {
		changes.forEach( change => {

			const ratio = change.intersectionRatio;

			if ( ratio > 0 && ! wasCSSLoaded ) {
				loadCSS();
				
				cssObserver.unobserve( change.target );
				// cssObserver = null;
			}
		} );
	}

	function loadCSS () {
		loadStyleSheet( 'assets/css/projects/' + id + '.css', id )
			.then( () => {
				wasCSSLoaded = true;
			} );
		// requestAnimationFrame( () => {
		// 	const cssId = id + '_stylesheet';

		// 	if ( ! document.getElementById( cssId ) ) {
		// 		const head  = document.getElementsByTagName('head')[0];
		// 		const linkEl  = document.createElement( 'link' );
		// 		linkEl.id   = cssId;
		// 		linkEl.rel  = 'stylesheet';
		// 		linkEl.type = 'text/css';
		// 		linkEl.href = 'assets/css/projects/' + id + '.css';
		// 		linkEl.media = 'all';
		// 		head.appendChild( linkEl );
		// 		wasCSSLoaded = true;
		// 	}
		// } );
	}
</script>