export function ready () {
	return new Promise( done => {
		if ( document.attachEvent ? document.readyState === 'complete' : document.readyState !== 'loading' ) {
			done();
		} else {
			document.addEventListener( 'DOMContentLoaded', done );
		}
	} );
}

// https://stackoverflow.com/a/31732310
export const isSafari = !!(navigator.vendor && navigator.vendor.indexOf('Apple') > -1 &&
               navigator.userAgent &&
               navigator.userAgent.indexOf('CriOS') == -1 &&
               navigator.userAgent.indexOf('FxiOS') == -1);