<figure
	class="embed-item embed-item-vimeo"
	bind:this="{el}"
	class:was-loaded="{wasLoaded}"
	class:has-caption="{showCaption}"
	bind:offsetWidth="{containerSize.width}"
	bind:offsetHeight="{containerSize.height}"
	data-embed-id="{embedId}"
>
	<div
		class="embed-container"
		style="padding-bottom:{ ratio * 100 }%"
	>
		<div class="embed-content">
			{#if canShow}
			<iframe
				src="{embedURL}"
				width="{embedWidth}"
				height="{embedHeight}"
				title="{embedAlt}"
				bind:this="{iframeEl}"
				frameborder="0"
				webkitallowfullscreen
				mozallowfullscreen
				allowfullscreen
			></iframe>
			{/if}
		</div>
	</div>
	{#if showCaption}
	<figcaption class="embed-caption">
		<div class="content-center">{embedCaption}</div>
	</figcaption>
	{/if}
</figure>

<script>
import { onMount, onDestroy } from 'svelte';
import { activeProjectIds, visibleProjectIds } from './stores/projects.js';
import {
	requestedEmbedIds,
	embedLoaded,
	wasEmbedLoaded,
	loadedEmbedIds,
	currentlyPlayingEmbedId,
	embedWasPaused,
	embedWasStarted,
	loadScript
} from './stores/embed.js';

export let linkEl = null;
export let embedId = null;
export let projectId = null;

let el = null;
let iframeEl = null;
let vimeoPlayer = null;

let isVisible = false;
let isObserving = false;
let isPlaying = false;

const containerSize = { width: 0, height: 0 };
const observerOptions = { threshold: [ 0, 0.2, 0.4, 0.6, 0.8, 1 ] };
const observer = new IntersectionObserver( observerChanged, observerOptions );

$ : isProjectOpen = $activeProjectIds.includes( projectId );
$ : isProjectVisible = $visibleProjectIds.includes( projectId );
$ : canShow = projectId ? isProjectOpen && isProjectVisible : true;

$ : embedURL = linkEl ? getEmbedURL( linkEl.getAttribute( 'href' ) ) : '';
$ : embedCaption = linkEl ? linkEl.textContent : null;
$ : embedAlt = linkEl ? ( linkEl.getAttribute( 'title' ) || embedCaption ) : embedCaption;
$ : showCaption = linkEl.getAttribute( 'data-hide-caption' ) ? false : true;
$ : settings = Object.assign( { }, linkEl.dataset || { } );
$ : wasLoaded = $loadedEmbedIds.includes( embedId );
$ : activeEmbedId = currentlyPlayingEmbedId;
$ : ratio = settings.embedHeight / settings.embedWidth;
$ : scale = containerSize.width / settings.embedWidth;
$ : embedWidth = Math.round( scale * settings.embedWidth );
$ : embedHeight = Math.round( scale * settings.embedHeight );
$ : ratioToPauseIfSmaller = embedHeight > window.innerHeight ? 0 : 0.4;

$ : {
	if ( ! isObserving && ! wasLoaded && canShow ) {
		startObserving();
	}
}

onMount( () => {
	linkEl.classList.add( 'was-instantiated' );

	document.addEventListener( 'visibilitychange', visibilityChanged, false );

	if ( projectId ) {
		activeProjectIds.subscribe( () => {
			if ( isProjectOpen ) {
				startObserving();
			} else {
				stopObserving();
				videoPausedBySystem();
			}
		} );
	} else {
		startObserving();
	}

	currentlyPlayingEmbedId.subscribe( newActiveEmbedId => {
		if ( newActiveEmbedId !== embedId ) {
			videoPausedBySystem();
		}
	} );
} );

onDestroy( () => {
	document.removeEventListener( 'visibilitychange', visibilityChanged, false );
	stopObserving();
} );

function startObserving () {
	if ( ! isObserving ) {
		observer.observe( el );
		isObserving = true;
	}
}

function stopObserving () {
	if ( isObserving ) {
		observer.unobserve( el );
		isObserving = false;
	}
}

function observerChanged ( changes ) {
	changes.forEach( change => {
		const el = change.target;
		const visibilityRatio = change.intersectionRatio;

		if ( ratio > 0 ) {
			embedBecameVisible();
		}

		if ( visibilityRatio <= ratioToPauseIfSmaller && isPlaying ) {
			videoPausedBySystem();
		}
	} );
}

function embedBecameVisible () {
	isVisible = true;

	if ( ! wasLoaded ) {

		loadPlayer()
			.then( () => {
				if ( ! vimeoPlayer && iframeEl ) {
					vimeoPlayer = new Vimeo.Player( iframeEl );

					vimeoPlayer.on( 'play', videoStartedByUser );
					vimeoPlayer.on( 'pause', videoPausedByUser );
					vimeoPlayer.on( 'ended', videoPausedByUser );

					embedLoaded( embedId );
				}
			} )
			.catch( err => console.log( err ) );
	}
}

function visibilityChanged () {
	if ( document.hidden ) {
		videoPausedBySystem();
	}
}

function loadPlayer () {
	return wasEmbedLoaded( embedId )
		? Promise.resolve()
		: loadScript( '//player.vimeo.com/api/player.js', 'Vimeo' );
}

function videoStartedByUser () {
	isPlaying = true;
	embedWasStarted( embedId );
}

function videoPausedByUser () {
	isPlaying = false;
	embedWasPaused();
}

function videoPausedBySystem () {
	if ( isPlaying && vimeoPlayer ) {
		vimeoPlayer.pause();
		isPlaying = false;
		embedWasPaused();
	}
}

function getEmbedURL ( linkHref ) {
	const videoId = getVideoId( linkHref );
	const playerUrl = `https:\/\/player.vimeo.com/video/${videoId}`;
	
	const iframeOptions = {
		portrait: 0,
		color: 'ffffff',
		dnt: 1
	};

	const urlParams = Object.keys( iframeOptions )
		.map( key => `${key}=${iframeOptions[key]}` )
		.join( '&' );

	const paramsStr = encodeURIComponent( urlParams );

	return `${playerUrl}?${urlParams}`;
}

function getVideoId ( embedURL ) {
	const hoster = getVideoHoster( embedURL );

	if ( hoster === 'vimeo' ) {
		return embedURL.split( '/' ).filter( str => str.length ).pop();
	} else {
		// if ( hoster === 'youtube' ) {
		// 	if ( embedURL.indexOf( 'tu.be' ) !== -1 ) {
		// 		return embedURL.split( '/' ).filter( str => str.length ).pop().split( '?' )[0];
		// 	} else {
		// 		const idPart = embedURL.split( 'v=' )[0];
		// 		return idPart ? idPart.split( '&' )[0] : null;
		// 	}
		// }

		// // https://youtu.be/QHOAxAeRdKs?t=57s
		return null;
	}
}

function getVideoHoster ( url ) {
	const hosters = [ 'youtube', 'vimeo' ];

	return hosters.reduce( ( result, hoster ) => {
		if ( ! result && url.indexOf( hoster ) !== -1 ) {
			result = hoster;
		}

		return result;
	} , null );
}

</script>