import { parseTime } from './math.js';

// matches polyfill
if ( Element && ! Element.prototype.matches ) {
	const proto = Element.prototype;
	proto.matches = proto.matchesSelector ||
		proto.mozMatchesSelector || proto.msMatchesSelector ||
		proto.oMatchesSelector || proto.webkitMatchesSelector;
}

export function getEl ( selector, parentEl = document ) {
	return parentEl.querySelector( selector );
}

export function getEls ( selector, parentEl = document ) {
	return toNodeArray( selector, parentEl );
}

export function getChildEls ( el ) {
	return toNodeArray( el.childNodes )
		.filter( isNode );
}

export function nodeListToArray ( nl ) {
	return [ ... nl ];
}

export function nodeToArray ( el ) {
	return [ el ];
}

export function toNodeArray ( els, parentEl = document ) {
	let result = [ ];

	if ( Array.isArray( els ) ) {
		result = els;
	} else {
		if ( typeof els === 'string' ) {
			result = nodeListToArray( parentEl.querySelectorAll( els ) );
		} else {
			if ( isNodeList( els ) ) {
				result = nodeListToArray( els );
			} else {
				if ( isNode( els ) ) {
					result = nodeToArray( els );
				}
			}
		}
	}

	return result.filter( isNode );
}

export function isNode ( el ) {
	return el instanceof HTMLElement || el instanceof SVGElement;
}

export function isNodeList ( el ) {
	return NodeList.prototype.isPrototypeOf( el )
}

export function cloneEl ( el ) {
	return el.cloneNode( true );
}

export function insertAfter( el, referenceNode ) {
	referenceNode.parentNode.insertBefore( el, referenceNode.nextSibling );
}

export function setCSS ( els, parameters ) {
	return new Promise ( resolve => {
		requestAnimationFrame ( () => {
			const keys = Object.keys( parameters );

			els.forEach( el => {
				keys.forEach( key => {
					el.style[key] = parameters[key];
				} );
			} );

			resolve( els );
		} );
	} );
}

export function createSVGEl ( type, attrs, parentEl = null ) {
	const el = document.createElementNS( 'http://www.w3.org/2000/svg', type );

	if ( attrs ) {
		for ( let key in attrs ) {
			if ( key == 'xlink:href' ) {
				el.setAttributeNS( 'http://www.w3.org/1999/xlink', 'href', attrs[key] );
			} else {
				el.setAttribute( key, attrs[key] );
			}
		}
	}

	if ( parentEl ) {
		parentEl.appendChild( el );
	}

	return el;
}

export function getElSize ( el = window ) {
	if ( ! el || el === window ) {
		return {
			w: window.innerWidth,
			h: window.innerHeight
		};
	} else {
		return {
			w: el.clientWidth,
			h: el.clientHeight
		}
	}
}

export function getElOffset ( el ) {
	let x = 0;
	let y = 0;
	
	while (
		el &&
		! isNaN( el.offsetLeft ) &&
		! isNaN( el.offsetTop )
	) {
		x += el.offsetLeft;
		y += el.offsetTop;
		el = el.offsetParent;
	}

	return { x, y };
}

export function isElEdgeInViewPort ( el, elSize, elOffset, winSize, winScrollTop ) {	
	if (
		elOffset.y >= winScrollTop &&
		elOffset.y <= winScrollTop + winSize.h
	) {
		return true;
	}

	if (
		elOffset.y + elSize.h >= winScrollTop &&
		elOffset.y + elSize.h <= winScrollTop + winSize.h
	) {
		return true;
	}

	return false;
}

export function isElOverTopOfViewPort ( el, elSize, elOffset, winSize, winScrollTop ) {
	return elOffset.y < winScrollTop;
}

export function isElBelowBottomOfViewPort ( el, elSize, elOffset, winSize, winScrollTop ) {
	return elOffset.y > winScrollTop + winSize.h;
}

export const eventTypes = [ 'click', 'mouseenter', 'mouseleave', 'mouseover', 'mouseout', 'keydown', 'keypress', 'keyup', 'blur', 'focus' ];

export function createEl () {
	const args = Array.from( arguments );

	const params = args.filter( arg => typeof arg === 'object' && ! isNode( arg ) )[0] || { };
	const parentEl = isNode( args[1] ) ? args[1] : params.parent;
	
	const typeStr = typeof args[0] === 'string' ? args[0] : 'div';
	const hasTemplateStr = typeStr.indexOf( '<' ) !== -1;
	const type = hasTemplateStr ? typeStr : typeStr.split( '.' )[0] || 'div';
	const el = hasTemplateStr ? strToEl( type ) : document.createElement( type );

	const classes = hasTemplateStr ? [ ] : typeStr.split( '.' ).slice( 1 )
		.reduce( ( res, classStr ) => res.concat( classStr.split( ' ' ) ), [ ] )
		.concat( typeof params.classes === 'string' ? params.classes.split( ' ' ) : params.classes )
		.filter( classStr => !! classStr && classStr.length );

	if ( classes.length ) {
		classes.forEach( cssClass => {
			el.classList.add( cssClass );
		} );
	}

	if ( params.textContent ) {
		el.textContent = params.textContent;
	}

	eventTypes.forEach( eventType => {
		if ( typeof params[eventType] === 'function' ) {
			el.addEventListener( eventType, params[eventType] );
		}
	} );

	Object.keys( params )
		.filter( key => [ 'parent', 'textContent', 'classes' ].concat( eventTypes ).indexOf( key ) === -1 )
		.forEach( key => {
			el.setAttribute( key, params[key] );
		} );

	if ( parentEl ) {
		parentEl.appendChild( el );
	}

	return el;
}

export function strToEl ( str ) {
	const template = document.createElement( 'template' );
	str = str.trim();
	template.innerHTML = str;

	return template.content.firstChild;
}

// export function getTransitionDuration ( el ) {
// 	const style = getComputedStyle( el );
	
// 	return ( isNode( el ) && el.style )
// 		? parseTime( style.transitionDuration || 0 ) + parseTime( style.transitionDelay || 0 )
// 		: 0;
// }

export function getTransitionDuration ( el ) {
	const computedStyles = getComputedStyle( el );
	
	const duration = parseTime( computedStyles.transitionDuration || '0' );
	const delay = parseTime( computedStyles.transitionDelay || '0' );

	return duration + delay;
}