<script>
import { activeProjectIds, isProjectOpen } from './stores/projects.js';
import { scrollPos } from './stores/scroll.js';

export let projectId, el, alwaysCheck;

let isVisible = false;
// let isFullyVisible = false;
let isOverTop = false;
let isBelowBottom = false;
let isInTopHalf = false;
let isInBottomHalf = false;
let isCheckingScroll = false;
let isOpen = false;

let scrollTimeoutId = null;

const observerOptions = { threshold: [ 0, 0.2, 0.4, 0.6, 0.8, 1 ] };
const observer = new IntersectionObserver( observerChanged, observerOptions );

function observerChanged ( changes ) {
	changes.forEach( change => {
		const el = change.target;
		const ratio = change.intersectionRatio;
			
		const newVisible = ratio > 0;
		const newFullyVisible = ratio > 0.95;

		if ( newVisible !== isVisible ) {
			requestAnimationFrame( () => {
				if ( newVisible ) {
					el.classList.add( 'is-visible' );
				} else {
					el.classList.remove( 'is-visible' );
				}

				isVisible = newVisible;
			} );
		}

		// if ( newFullyVisible !== isFullyVisible ) {
		// 	requestAnimationFrame( () => {
		// 		if ( newFullyVisible ) {
		// 			el.classList.add( 'is-fully-visible' );
		// 		} else {
		// 			el.classList.remove( 'is-fully-visible' );
		// 		}

		// 		isFullyVisible = newVisible;
		// 	} );
		// }
	} );
}

if ( projectId && ! alwaysCheck ) {
	activeProjectIds.subscribe( ids => {
		const newOpen = isProjectOpen( projectId );

		if ( newOpen !== isOpen ) {
			isOpen = newOpen;

			if ( isOpen ) {
				// setTimeout( () => {
					observer.observe( el );
					isCheckingScroll = true;
				// }, 500 );
			} else {
				observer.unobserve( el );
				isCheckingScroll = false;

				if ( isVisible ) {
					isVisible = false;
					// isFullyVisible = false;
					el.classList.remove( 'is-visible' );
					// el.classList.remove( 'is-fully-visible' );
				}
			}
		}
	} );
} else {
	observer.observe( el );
}

scrollPos.subscribe( scrollTop => {
	if ( isCheckingScroll ) {
		clearTimeout( scrollTimeoutId );

		scrollTimeoutId = setTimeout( () => {
			const bb = el.getBoundingClientRect();

			const newOverTop = bb.top <= 0;
			const newBelowBottom = bb.top + bb.height > window.innerHeight;
			
			const newInTopHalf = bb.top + bb.height < window.innerHeight / 2;
			const newInBottomHalf = bb.top > window.innerHeight / 2;

			if ( newOverTop !== isOverTop ) {
				requestAnimationFrame( () => {
					if ( newOverTop ) {
						el.classList.add( 'is-over-top' );
					} else {
						el.classList.remove( 'is-over-top' );
					}

					isOverTop = newOverTop;
				} );
			}

			if ( newBelowBottom !== isBelowBottom ) {
				requestAnimationFrame( () => {
					if ( newBelowBottom ) {
						el.classList.add( 'is-below-bottom' );
					} else {
						el.classList.remove( 'is-below-bottom' );
					}

					isBelowBottom = newBelowBottom;
				} );
			}

			if ( newInTopHalf !== isInTopHalf ) {
				requestAnimationFrame( () => {
					if ( newInTopHalf ) {
						el.classList.add( 'is-in-top-half' );
					} else {
						el.classList.remove( 'is-in-top-half' );
					}

					isInTopHalf = newInTopHalf;
				} );
			}

			if ( newInBottomHalf !== isInBottomHalf ) {
				requestAnimationFrame( () => {
					if ( newInBottomHalf ) {
						el.classList.add( 'is-in-bottom-half' );
					} else {
						el.classList.remove( 'is-in-bottom-half' );
					}

					isInBottomHalf = newInBottomHalf;
				} );
			}
		}, 250 );
	}
} );
</script>