import { writable, get } from 'svelte/store';
import detectIt from 'detect-it';

export const isMouse = writable( false );
export const isTouch = writable( false );

export function init () {
	update();
	setTimeout( update, 3000 );
	document.addEventListener( 'visibilitychange', update, false );
}

// UPDATE ON WINDOW VISIBILITY?
function update () {
	isMouse.set( detectIt.primaryInput === 'mouse' );
	isTouch.set( detectIt.primaryInput === 'touch' );
}

isMouse.subscribe( newMouse => {
	requestAnimationFrame( () => {
		if ( newMouse ) {
			document.documentElement.classList.add( 'is-mouse' );
		} else {
			document.documentElement.classList.remove( 'is-mouse' );
		}
	} )
} );

isTouch.subscribe( newTouch => {
	requestAnimationFrame( () => {
		if ( newTouch ) {
			document.documentElement.classList.add( 'is-touch' );
		} else {
			document.documentElement.classList.remove( 'is-touch' );
		}
	} )
} );

