export function random ( min, max, intResult ) {
	if ( Array.isArray( min ) ) {
		return min[~~( Math.random() * min.length )];
	} else {
		if ( typeof min !== 'number' && typeof max !== 'number' ) {
			min = 0;
			max = 1;
		}

		if ( typeof max !== 'number' ) {
			max = min;
			min = 0;

			if ( typeof max === 'boolean' ) {
				intResult = true;
			}
		}

		let result = min + Math.random() * ( max - min );

		if ( intResult ) {
			result = parseInt( result, 10 );
		}
	
		return result;
	}
}

export function clamp ( value, min, max ) {
	return Math.min( Math.max( value, min ), max );
}

export function mapRange ( value, inMin, inMax, outMin, outMax, clampResult ) {
	let result = ( ( value - inMin ) / ( inMax - inMin ) * ( outMax - outMin ) + outMin );

	if ( clampResult ) {
		if ( outMin > outMax ) {
			result = Math.min( Math.max( result, outMax ), outMin );
		} else {
			result = Math.min( Math.max( result, outMin ), outMax );
		}
	}

	return result;
}

// 1.2s 1200ms > 1200;
export function parseTime ( str ) {
	return typeof str === 'string' ? parseFloat( str ) * ( str.indexOf( 'ms' ) >- 1 ? 1 : 1000 ) : 0;
}