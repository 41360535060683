import { writable, derived, get } from 'svelte/store';
import { tick } from 'svelte';

export const loadedEmbedIds = writable( [ ] );
export const requestedEmbedIds = writable( [ ] );
export const loadedScripts = writable( [ ] );
export const requestedScripts = writable( [ ] );
export const currentlyPlayingEmbedId = writable( null );

export function embedLoaded ( id ) {
	loadedEmbedIds.set( [ ... get( loadedEmbedIds ), id ] );
}

export function wasEmbedLoaded ( id ) {
	return get( loadedEmbedIds ).includes( id );
}

export function requestEmbed ( id ) {
	const embedIds = get( requestedEmbedIds );

	if ( ! embedIds.includes( id ) ) {
		requestedEmbedIds.set( [ ... embedIds, id ] );
	}
}

export function loadScript ( src, globals = [ ] ) {
	if (
		! get( loadedScripts ).includes( src ) &&
		! get( requestedScripts ).includes( src )
	) {		
		return new Promise ( ( done, fail ) => {
			const scriptEl = document.createElement( 'script' );
						
			scriptEl.addEventListener( 'error', fail );

			scriptEl.addEventListener( 'load', () => {
				tick()
					.then( () => {
						loadedScripts.set( [ ... get( loadedScripts ), src ] );
						done( src );
					} );
			} );

			scriptEl.src = src;
			document.body.appendChild( scriptEl );
		} );
	} else {
		// wait for src to finish loading...
		if (
			! get( loadedScripts ).includes( src ) &&
			get( requestedScripts ).includes( src )
		) {
			const unsubscribe = loadedScripts.subscribe( allSrcs => {
				if ( allSrcs.includes( src ) ) {
					loaded();
				}
			} );
			
			const loaded = () => {
				unsubscribe();
				done();
			};

		} else {
			return Promise.resolve( src );
		}
	}
}

export function embedWasStarted ( id ) {
	currentlyPlayingEmbedId.set( id );
}

export function embedWasPaused ( id ) {
	currentlyPlayingEmbedId.set( null );
}