import App from './App.svelte';
import Project from './Project.svelte';
import ContentItem from './ContentItem.svelte';
import Carousel from './Carousel.svelte';
// import Embed from './Embed.svelte';
import EmbedImage from './EmbedImage.svelte';
import EmbedVideo from './EmbedVideo.svelte';
import EmbedVimeo from './EmbedVimeo.svelte';
import EmbedIframe from './EmbedIframe.svelte';
import Text from './Text.svelte';
import { init as initScroll } from './stores/scroll.js';
import { init as initInputDevices } from './stores/inputDevices.js';
import Splitting from 'splitting';
import { ready } from './util/browser.js';
import { loadStyleSheet } from './util/css.js';
import { ScrollNav } from './ScrollNav.js';
import { navigateToProject, scrollToElement } from './stores/projects.js';

let app = null;
let wasCSSLoaded = false;

ready()
	.then( () => {
		loadCSS();
		initScroll();
		initInputDevices();
		initApp();

		const navEl = document.querySelector( '.nav' );
		new ScrollNav( navEl, '[data-subnav-label]' );
	} );


function initApp () {

	app = new App( {
		target: document.body
	} );

	let embedId = 1;

	const projects = Array
		.from( document.querySelectorAll( '.project' ) )
		.map( projectEl => {
			const id = projectEl.getAttribute( 'id' ).toString();
			const contentWrapperEl = projectEl.querySelector( '.project-main' );
			const contentEl = projectEl.querySelector( '.project-content' );
			const buttonContainerEl = projectEl.querySelector( '.project-header-content' );
			// const projectHeaderEl = projectEl.querySelector( '.project-header' );

			const projectContentItems = Array.from( projectEl.querySelectorAll( '.project-content-item' ) );
			// const projectContentItems = Array.from( projectEl.querySelectorAll( '.content-item' ) );

			projectContentItems.forEach( contentItemEl => {
				new ContentItem( {
					target: contentItemEl,
					props: {
						projectId: id,
						el: contentItemEl,
						alwaysCheck: false
					} 
				} );
			} );

			Array.from( projectEl.querySelectorAll( '.embed-link' ) )
				.filter( linkEl => ! linkEl.closest( '.carousel-container' ) )
				.forEach( linkEl => {
					const embedWrapperEl = document.createElement( 'div' );
					embedWrapperEl.classList.add( 'embed-wrapper' );

					linkEl.parentNode.insertBefore(embedWrapperEl, linkEl.nextSibling);

					if ( linkEl.getAttribute( 'data-embed-type' ) === 'image' ) {
						new EmbedImage( {
							target: embedWrapperEl,
							props: {
								projectId: id,
								linkEl,
								embedId: id + '_' + embedId
							}
						} );
					} else {
						if ( linkEl.getAttribute( 'data-embed-type' ) === 'video' ) {
							new EmbedVideo( {
								target: embedWrapperEl,
								props: {
									projectId: id,
									linkEl,
									embedId: id + '_' + embedId
								}
							} );
						} else {
							if ( linkEl.getAttribute( 'data-embed-type' ) === 'vimeo' ) {
								new EmbedVimeo( {
									target: embedWrapperEl,
									props: {
										projectId: id,
										linkEl,
										embedId: id + '_' + embedId
									}
								} );
							} else {
								if ( linkEl.getAttribute( 'data-embed-type' ) === 'iframe' ) {
									new EmbedIframe( {
										target: embedWrapperEl,
										props: {
											projectId: id,
											linkEl,
											embedId: id + '_' + embedId
										}
									} );
								}
							}
						}
					}

					embedId++;
				} );

			if (
				window.innerWidth > 960 &&
				!! window.IntersectionObserver
			) {
				projectContentItems.forEach( textEl => {
					new Text( {
						target: textEl,
						props: { textEl, id }
					} );
				} );
			}

			const carouselContainerEls = Array.from( projectEl.querySelectorAll( '.carousel-container' ) );
			let lastId = null;

			carouselContainerEls.forEach( ( carouselContainerEl, indexInProject ) => {
				const itemEls = Array.from( carouselContainerEl.querySelectorAll( '.carousel-item > .embed-link' ) );
				
				new Carousel( {
					target: carouselContainerEl,
					props: {
						projectId: id,
						items: itemEls,
						perPage: 1
					}
				} );

				carouselContainerEl.classList.add( 'was-initialized' );
			} );

			return new Project( {
				target: buttonContainerEl,
				props: { id, projectEl, contentEl, contentWrapperEl }
			} );
		} );

	Array.from( document.querySelectorAll( '.is-splitting-by-char' ) )
		.forEach( spanEl => {
			new Splitting( { target: spanEl, by: 'chars' } );
		} );

	if ( window.innerWidth >= 1200 ) {
		Array.from( document.querySelectorAll( '.is-splitting-by-word' ) )
			.forEach( spanEl => {
				new Splitting( { target: spanEl, by: 'words' } );
			} );
	}

	Array.from( document.querySelectorAll( '#home a[href^="#"]' ) )
		.forEach( linkEl => {
			const selector = linkEl.getAttribute( 'href' );
			const targetEl = document.querySelector( selector );
			
			if ( targetEl ) {
				if ( targetEl.classList.contains( 'project' ) ) {
					linkEl.addEventListener( 'click', event => {
						event.preventDefault();
						navigateToProject( targetEl.id );
					} );
				} else {
					linkEl.addEventListener( 'click', event => {
						event.preventDefault();
						scrollToElement( targetEl );
					} );
				}
			}
		} );

}

function loadCSS () {
	if ( ! wasCSSLoaded ) {
		loadStyleSheet( 'assets/css/has-js.css', 'has-.js' )
			.then( () => {
				wasCSSLoaded = true;
			} );
	}
}

export default app;