// https://gist.github.com/aderaaij/a6b666bf756b2db1596b366da921755d
export function getComputedTranslateXY ( el ) {
	const transArr = [ ];
	// if ( ! window.getComputedStyle ) return;
	const style = getComputedStyle( el );
	const transform = style.transform || style.webkitTransform || style.mozTransform;
	
	let mat = transform.match(/^matrix3d\((.+)\)$/);    
	
	if ( mat ) {
		return parseFloat( mat[1].split(', ') [13] );
	}

	mat = transform.match( /^matrix\((.+)\)$/ );
	mat ? transArr.push( parseFloat( mat[1].split( ', ' )[4] ) ) : 0;
	mat ? transArr.push( parseFloat( mat[1].split( ', ' )[5] ) ) : 0;
	
	return transArr;
}

export function loadStyleSheet ( href, id ) {

	return new Promise( ( resolve, reject ) => {
		id = id || href;
		
		requestAnimationFrame( () => {
			const cssId = id + '_stylesheet';

			if ( ! document.getElementById( cssId ) ) {
				const head = document.getElementsByTagName('head')[0];
				const linkEl = document.createElement( 'link' );

				linkEl.onload = resolve;
				linkEl.onerror = reject;

				linkEl.id   = cssId;
				linkEl.rel  = 'stylesheet';
				linkEl.type = 'text/css';
				// linkEl.href = 'assets/css/projects/' + id + '.css';
				linkEl.href = href;
				linkEl.media = 'all';
				head.appendChild( linkEl );
			}
		} );
	} );
}