<figure
	class="embed-item embed-item-image"
	bind:this="{el}"
	class:was-loaded="{wasLoaded}"
	class:has-caption="{showCaption}"
	class:is-in-carousel="{isInCarousel}"
	data-embed-id="{embedId}"
>
	<div
		class="embed-container"
		style="padding-bottom:{ ratio * 100 }%"
	>
		<div class="embed-content">
			{#if wasLoaded }
			<img
				class="embed-image"
				src="{src}"
				srcset="{srcset}"
				alt="{embedAlt}"
			/>
			{/if}
		</div>
	</div>
	{#if showCaption}
	<figcaption class="embed-caption">
		<div class="content-center">{embedCaption}</div>
	</figcaption>
	{/if}
</figure>

<script>
import { onMount, onDestroy } from 'svelte';
import { activeProjectIds, visibleProjectIds } from './stores/projects.js';
import { requestedEmbedIds, embedLoaded, wasEmbedLoaded, loadedEmbedIds } from './stores/embed.js';

export let linkEl = null;
export let embedId = null;
export let projectId = null
export let isInCarousel = false;
let imageSrc = null;
let settings = { };

const observerOptions = { threshold: [ 0, 0.2, 0.4, 0.6, 0.8, 1 ] };
const observer = new IntersectionObserver( observerChanged, observerOptions );

let el = null;
let isVisible = false;
let isObserving = false;

$ : isProjectOpen = $activeProjectIds.includes( projectId );
$ : isProjectVisible = $visibleProjectIds.includes( projectId );
$ : canShow = projectId ? isProjectOpen && isProjectVisible : true;

$ : embedURL = linkEl ? linkEl.getAttribute( 'href' ) : '';
$ : embedCaption = linkEl ? linkEl.textContent : null;
$ : embedAlt = linkEl ? ( linkEl.getAttribute( 'title' ) || embedCaption ) : embedCaption;
$ : showCaption = linkEl.getAttribute( 'data-hide-caption' ) ? false : true;
$ : {
	if ( linkEl.dataset ) {
		settings = Object.assign( { }, linkEl.dataset || { } );
		imageSrc = getImageSrc( settings );
	}
}

$ : wasLoaded = $loadedEmbedIds.includes( embedId );
$ : ratio = settings.embedHeight / settings.embedWidth;
$ : srcset = imageSrc ? imageSrc.srcset : '';
$ : src = imageSrc ? imageSrc.src : '';

$ : {
	if ( ! isObserving && ! wasLoaded && canShow ) {
		startObserving();
	}
}

onMount( () => {
	linkEl.classList.add( 'was-instantiated' );

	if ( ! wasLoaded ) {
		if ( projectId ) {
			activeProjectIds.subscribe( () => {
				if ( ! wasLoaded ) {
					if ( isProjectOpen ) {
						startObserving();
					} else {
						stopObserving();
					}
				}
			} );
		} else {
			startObserving();
		}

		requestedEmbedIds.subscribe( embedIds => {
			if ( embedIds.includes( embedId ) ) {
				embedBecameVisible();
			}
		} );
	}
} );

onDestroy( () => {
	stopObserving();
} );

function startObserving () {
	if ( ! isObserving && imageSrc ) {
		observer.observe( el );
		isObserving = true;
	}
}

function stopObserving () {
	if ( isObserving ) {
		observer.unobserve( el );
		isObserving = false;
	}
}

function observerChanged ( changes ) {
	changes.forEach( change => {
		const el = change.target;
		const visibilityRatio = change.intersectionRatio;

		if ( canShow && visibilityRatio > 0 && ! isVisible ) {
			embedBecameVisible();
		}
	} );
}

function embedBecameVisible () {
	isVisible = true;

	stopObserving();

	if ( ! wasLoaded ) {
		loadImage()
			.then( () => {
				embedLoaded( embedId );
			} )
			.catch( err => console.log( err.message || err ) );
	}
}

function getImageSrc ( settings ) {
	const src2X = settings ? settings['src-2x'] : '';

	const result = {
		src: linkEl.getAttribute( 'href' )
	};

	if ( src2X && src2X.length ) {
		if ( settings.embedWidth ) {
			const widthX1 = parseInt( settings.embedWidth, 10 ) * 0.5;

			result.srcset = `${result.src} ${widthX1}w, ${src2X} ${settings.embedWidth}w`
		} else {
			result.srcset = `${result.src} 1x, ${src2X} 2x`;
		}
	}
	
	return result;
}

// LOAD IMAGE IF SETTINGS IS SET!
function loadImage () {
	if ( projectId.indexOf( 'insult') > -1 ) {
		console.log( 'loadImage', { srcset, src } );
	}

	return new Promise( ( resolve, reject ) => {
		const img = new Image();
		img.onload = resolve;
		img.onerror = reject;

		if ( srcset ) {
			img.srcset = srcset
		} else {
			img.src = src;
		}

	} );
}

</script>