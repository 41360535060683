// https://gist.github.com/andjosh/6764939

export function scrollTo ( endPos, props = { } ) {
  const scrollEl = props.el || document.scrollingElement || document.documentElement;
  const startPos = scrollEl.scrollTop;
  const delta = endPos - startPos;
  const distance = Math.abs( delta );
  const speed = props.speed || 100;
  const durationSpeed = distance / ( speed / 1000 );
  const duration = props.duration || durationSpeed;
  
  return new Promise( ( resolve, reject ) => {
    const startDate = Date.now();

    const animateScroll = () => {
      const currentDate = Date.now();
      const currentTime = currentDate - startDate;
      const val = easeInOutQuad( currentTime, startPos, delta, duration );
            
      if ( currentTime < duration ) {
        scrollEl.scrollTop = val;

        requestAnimationFrame( animateScroll );
      } else {
        scrollEl.scrollTop = endPos;
        resolve();
      }
    };

    animateScroll();
  } );
}

export function scrollToEl ( el, props = { } ) {
  if ( el ) {
    const scrollEl = document.scrollingElement || document.documentElement;
    const top = el.getBoundingClientRect().top + scrollEl.scrollTop + ( props.offset || 0 );

    return scrollTo( top, props );
  }
}

function easeInOutQuad ( time, startValue, changeInValue, duration )  {
        time /= duration / 2;
        
        if ( time < 1 ) {
          return changeInValue / 2 * time * time + startValue;
        }

        time--;
        return -changeInValue/2 * (time*(time-2) - 1) + startValue;
}