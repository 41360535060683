<figure
	class="embed-item embed-item-vimeo"
	bind:this="{el}"
	class:was-loaded="{wasLoaded}"
	class:has-caption="{showCaption}"
	bind:offsetWidth="{containerSize.width}"
	bind:offsetHeight="{containerSize.height}"
	data-embed-id="{embedId}"
>
	<div
		class="embed-container"
		style="padding-bottom:{ ratio * 100 }%"
	>
		<div class="embed-content">
			{#if canShow}
			<iframe
				class="embed-frame"
				src="{iframeURL}"
				width="{embedWidth}"
				height="{embedHeight}"
				title="{embedAlt}"
				frameborder="0"
				bind:this="{iFrameEl}"
				on:load="{iframeWasLoaded}"
				webkitallowfullscreen
				mozallowfullscreen
				allowfullscreen
			></iframe>
			{/if}
		</div>
	</div>
	{#if showCaption}
	<figcaption class="embed-caption">
		<div class="content-center">{embedCaption}</div>
	</figcaption>
	{/if}
</figure>

<script>
import { onMount, onDestroy } from 'svelte';
import { activeProjectIds, visibleProjectIds } from './stores/projects.js';
import {
	requestedEmbedIds,
	embedLoaded,
	wasEmbedLoaded,
	loadedEmbedIds,
	currentlyPlayingEmbedId,
	embedWasPaused,
	embedWasStarted,
	loadScript
} from './stores/embed.js';

export let linkEl = null;
export let embedId = null;
export let projectId = null;

let el = null;
let iFrameEl = null;

let isVisible = false;
let isObserving = false;
let isPlaying = false;

const containerSize = { width: 0, height: 0 };
const observerOptions = { threshold: [ 0, 0.2, 0.4, 0.6, 0.8, 1 ] };
const observer = new IntersectionObserver( observerChanged, observerOptions );

$ : isProjectOpen = $activeProjectIds.includes( projectId );
$ : isProjectVisible = $visibleProjectIds.includes( projectId );
$ : canShow = projectId ? isProjectOpen && isProjectVisible : true;

$ : {
	if ( ! isObserving && ! wasLoaded && canShow ) {
		startObserving();
	}
}

$ : embedURL = linkEl ? linkEl.getAttribute( 'href' ) : '';
$ : iframeURL = isVisible ? embedURL : '';
$ : embedCaption = linkEl ? linkEl.textContent : null;
$ : embedAlt = linkEl ? ( linkEl.getAttribute( 'title' ) || embedCaption ) : embedCaption;
$ : showCaption = linkEl.getAttribute( 'data-hide-caption' ) ? false : true;
$ : settings = Object.assign( { }, linkEl.dataset || { } );
$ : wasLoaded = $loadedEmbedIds.includes( embedId );
$ : activeEmbedId = currentlyPlayingEmbedId;
$ : ratio = settings.embedHeight / settings.embedWidth;
$ : scale = containerSize.width / settings.embedWidth;
$ : embedWidth = Math.round( scale * settings.embedWidth );
$ : embedHeight = Math.round( scale * settings.embedHeight );
$ : ratioToPauseIfSmaller = embedHeight > window.innerHeight ? 0 : 0.4;

onMount( () => {
	linkEl.classList.add( 'was-instantiated' );

	document.addEventListener( 'visibilitychange', visibilityChanged, false );

	if ( projectId ) {
		activeProjectIds.subscribe( () => {
			if ( isProjectOpen ) {
				startObserving();
			} else {
				stopObserving();
				iFramePausedBySystem();
			}
		} );

		currentlyPlayingEmbedId.subscribe( newActiveEmbedId => {
			if ( newActiveEmbedId !== embedId ) {
				iFramePausedBySystem();
			}
		} );
	} else {
		startObserving();
	}
} );

onDestroy( () => {
	document.removeEventListener( 'visibilitychange', visibilityChanged, false );
	iFramePausedBySystem();
	stopObserving();
} );

function startObserving () {
	if ( ! isObserving ) {
		observer.observe( el );
		isObserving = true;
	}
}

function stopObserving () {
	if ( isObserving ) {
		observer.unobserve( el );
		isObserving = false;
	}
}

function observerChanged ( changes ) {
	changes.forEach( change => {
		const el = change.target;
		const visibilityRatio = change.intersectionRatio;

		if ( ratio > 0 ) {
			embedBecameVisible();
		}

		if ( visibilityRatio < ratioToPauseIfSmaller && isPlaying ) {
			iFramePausedBySystem();
		}
	} );
}

function iframeWasLoaded ( event ) {
	if (
		iFrameEl &&
		embedURL !== '' &&
		iFrameEl.getAttribute( 'src' ) === embedURL
	) {
		iFrameEl.contentWindow.addEventListener( 'message', iframeMessageReived );
		embedLoaded( embedId );
	}
}

function embedBecameVisible () {
	isVisible = true;
}

function visibilityChanged () {
	if ( document.hidden ) {
		iFramePausedBySystem();
	}
}

function iframeMessageReived ( { data } ) {	
	if ( data ) {
		const { type } = data;

		if ( type === 'play' ) {
			iFrameStartedByUser();
		}

		if ( type === 'pause' ) {
			iframePausedByUser();
		}
	}
}

function iFrameStartedByUser () {
	isPlaying = true;
	embedWasStarted( embedId );
}

function iframePausedByUser () {
	isPlaying = false;
	embedWasPaused();
}

function iFramePausedBySystem () {	
	if ( iFrameEl && isPlaying ) {
		iFrameEl.contentWindow.postMessage( { type: 'pause' }, location.origin );
		isPlaying = false;
		embedWasPaused();
	}
}

</script>