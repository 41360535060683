import {
	getEl,
	getEls,
	getChildEls,
	getElOffset,
	getElSize,
	createEl,
	isNode,
	getTransitionDuration
} from './util/dom.js';

import { tick } from 'svelte';
import { clamp, mapRange } from './util/math.js';
import { lastChangedProjectId, scrollToProject, scrollToElement } from './stores/projects.js';

let navInstances = [ ];

lastChangedProjectId.subscribe( change => {
	const id = change.split( '|' )[0];

	if ( id && id.length ) {
		tick()
			.then( () => {
				navInstances.forEach( nav => {
					requestAnimationFrame( () => {
						nav.handleProjectToggle( {
							el: document.querySelector( '#' + id )
						} );
					} )
				} );
			} );
	}
} );

export class ScrollNav {
	constructor ( navEl, subElsSelector ) {
		this.isActive = true;

		this.navEl = navEl;
		this.subElsSelector = subElsSelector;

		this.navItemEls = getEls( '.nav-item', this.navEl );
		this.navLinkEls = this.navItemEls.map( itemEl => getEl( '.nav-link', itemEl ) );
		this.navItemDimensions = this.navItemEls.map( navItemEl => getElDimensions( getEl( '.nav-link', navItemEl ) ) );
		this.navItemTransforms = [ ];
		this.navItemPaddingToTarget = 30;

		this.targetSelectors = this.navLinkEls.map( linkEl => linkEl.getAttribute( 'href' ) );
		this.targetEls = this.targetSelectors.map( selector => getEl( selector ) );
		this.targetDimensions = this.targetEls.map( getElDimensions );

		this.subnavData = this.createSubnavs();
		this.subnavEls = this.subnavData.map( data => data.subnavEl ).filter( isNode );
		this.subnavTargetEls = this.subnavData.map( ( subnavData, snIndex ) => subnavData.map( ( itemData, index ) => getEl( itemData.selector, this.targetEls[snIndex] ) ) );
		this.subnavDimensions = this.subnavEls.map( getElDimensions );
		this.subnavItemEls = this.subnavData.map( data => data.map( item => item.el ).filter( isNode ) );
		this.subnavItemDimensions = this.subnavItemEls.map( items => items.map( getElDimensions ) );
		this.subnavTargetDimensions = this.subnavTargetEls.map( subnavItems => subnavItems.map( getElDimensions ) );

		this.subnavItemPositions = this.subnavData.map( subnavItems => subnavItems.map( item => 0 ) );
		this.subnavItemLimits = this.subnavData.map( subnavItems => subnavItems.map( item => { return { min: 0, max: 0 } } ) );
		this.subNavItemTransforms = this.subnavData.map( subnavItems => subnavItems.map( item => 0 ) );

		this.updateAnimationFrameId = null;
		this.resizeAnimationFrameId = null;
		this.scrollAnimationFrameId = null;

		this.maxStackItemCount = 2;

		this.navDimensions = { y: 0, height: 0 };
		this.scrollPos = getScrollPos();

		// CHECK PASSIVE LISTENERS
		window.addEventListener( 'scroll', this.update.bind( this ) );
		window.addEventListener( 'resize', this.resize.bind( this ) );
		document.addEventListener( 'visibilitychange', this.visibilityChanged.bind( this ), false );

		this.resize();

		navInstances.push( this );
	}

	handleProjectToggle ( event ) {
		const startTime = Date.now();
		const transitionEl = event.el.querySelector( '.project-main' );
		const transitionDuration = getTransitionDuration( transitionEl );
				
		const updateAfterToggle = function () {
			this.update();

			if ( Date.now() - startTime < transitionDuration ) {
				requestAnimationFrame( updateAfterToggle.bind( this ) );
			}
		}.bind( this );
		
		updateAfterToggle();

		setTimeout( () => {
			this.resize();
			this.update();

			requestAnimationFrame( () => {
				this.update();
			} );
		}, transitionDuration );
	}

	createSubnavs () {
		const subnavData = this.targetEls.map( targetEl => getEls( this.subElsSelector, targetEl )
			.map( el => {
				return {
					id: el.id,
					label: el.getAttribute( 'data-subnav-label' ),
					selector: '#' + el.id
				};
			} )
		);

		this.navItemEls.forEach( ( navItemEl, index ) => {
			const subnavItemData = subnavData[index];

			const navItemLinkEl = getEl( 'a', navItemEl );

			if ( navItemLinkEl ) {
				navItemLinkEl.addEventListener( 'click', event => {
					const href = navItemLinkEl.getAttribute( 'href' );
					const targetEl = getEl( href );

					if ( targetEl ) {
						event.preventDefault();
						scrollToElement( targetEl );
					}
				} );
			}
						
			if ( subnavItemData && subnavItemData.length ) {
				const subnavEl = createEl( 'ul.sub-nav-items', navItemEl );
				subnavItemData.subnavEl = subnavEl;

				subnavItemData.forEach( ( itemData, itemIndex ) => {
					const itemEl = createEl( 'li.sub-nav-item', subnavEl );
					const href = itemData.selector;
					// const textContent = itemIndex + '/' + itemData.label;
					const textContent = itemData.label;
					const linkEl = createEl( 'a.sub-nav-link', itemEl, { href, textContent } );

					linkEl.addEventListener( 'click', event => {
						event.preventDefault();
						scrollToProject( href.replace( '#', '' ) );
					} );

					itemData.index = itemIndex;
					itemData.el = itemEl;
					itemData.linkEl = linkEl;
				} );
			}
		} );

		return subnavData;
	}

	resize () {
		cancelAnimationFrame( this.resizeAnimationFrameId );

		this.resizeAnimationFrameId = requestAnimationFrame( this.rawResize.bind( this ) );
	}

	visibilityChanged () {
		if ( ! document.hidden ) {
			this.resize();
		}
	}

	rawResize () {
		if ( window.innerWidth >= 1200 ) {
			this.isActive = true;
			this.updateNavDimensions();
			this.updateSubnavItemDimensions();
			this.updateTargetDimensions();
			this.rawUpdate();
		} else {
			this.isActive = false;
		}
	}

	update () {
		if ( this.isActive ) {
			this.updateScrollPos();
			this.rawUpdate();
		}

		// cancelAnimationFrame( this.updateAnimationFrameId );

		// this.updateAnimationFrameId = requestAnimationFrame( this.rawUpdate.bind( this ) );
	}

	rawUpdate () {
		if ( this.isActive ) {
			this.updateSubnavTargetDimensions();
			this.updateNavItemTransforms();
			this.updateSubnavDimensions();
			this.updateSubnavItemPositions();
			this.updateSubnavItemLimits();
			this.updateSubnavItemTransforms();
			this.render();
		}
	}
	
	updateScrollPos () {
		const newScrollPos = getScrollPos();

		if ( newScrollPos !== this.scrollPos ) {
			this.scrollPos = newScrollPos;

			clearTimeout( this.scrollAnimationFrameId );
			
			// this.scrollAnimationFrameId = setTimeout( function () {
			// 	eventBus.emit( 'scroll', { scrollPos: this.scrollPos } );
			// }.bind( this ), 40 );
		}
	}

	updateTargetDimensions () {
		this.targetDimensions = this.targetEls.map( getBBElDimensions );
		this.subnavTargetDimensions = this.subnavTargetEls.map( subnavItems => subnavItems.map( getBBElDimensions ) );
	}
	
	updateNavDimensions () {
		this.navDimensions.y = this.navEl.getBoundingClientRect().top;
		this.navDimensions.height = this.navEl.offsetHeight;
		this.navItemDimensions = this.navItemEls.map( getElDimensions );
	}

	updateNavItemTransforms () {
		const transformsToTarget = this.navItemEls.map( ( navItemEl, index ) => {
			const targetY = this.targetDimensions[index].y;
			const navY = this.navDimensions.y;
			const itemY = this.navItemDimensions[index].y;

			return ( targetY - this.scrollPos ) - ( itemY + navY ) + this.navItemPaddingToTarget;					
		} );

		this.navItemTransforms = transformsToTarget.map( ( transformY, index ) => {
			// min: all previous items height and Ypos
			
			const minY = this.navItemTransforms.reduce( ( minY, transformY, transformIndex ) => {
				if ( transformIndex < index ) {
					minY += this.navItemDimensions[transformIndex].height;
				}

				return minY;
			}, -this.navItemDimensions[index].y );

			return clamp( transformY, minY, 0 );
		} );
	}

	updateSubnavDimensions () {
		this.subnavDimensions = this.navItemTransforms.map( ( transformY, index ) => {
			let height = 0;

			// height = distance to next nav item
			if ( index < this.navItemTransforms.length - 1 ) {
				const navItemHeight = this.navItemDimensions[index].height;
				
				height = this.navItemTransforms[index + 1] - transformY;				
				height = Math.max( height, 0 );
			}

			// subnav y on page
			const y = this.navDimensions.y + this.navItemDimensions[index].y + this.navItemDimensions[index].height + transformY;

			return { height, y };
		} );
	}

	updateSubnavItemDimensions () {
		this.subnavItemDimensions = this.subnavItemEls.map( items => items.map( getElDimensions ) );
	}

	updateSubnavTargetDimensions () {
		this.subnavTargetDimensions = this.subnavTargetEls.map( subnavItems => subnavItems.map( getElDimensions ) );
	}

	updateSubnavItemPositions () {
		this.subNavItemPositions = this.subnavData.map( ( snItems, snIndex ) => snItems.map( ( item, index ) => {
			const targetY = this.subnavTargetDimensions[snIndex][index].y;
			const subnavDimensions = this.subnavDimensions[snIndex];
			const subnavItemDimensions = this.subnavItemDimensions[snIndex][index];
			
			const prevItemHeights = this.subnavData[snIndex].reduce( ( height, snItem, itemIndex ) => {
				return itemIndex < index - 1 ? height + this.subnavItemDimensions[snIndex][itemIndex].height : height;
			}, 0 );

			const transformY = targetY - subnavDimensions.y - this.scrollPos + this.navItemPaddingToTarget;
			
			return transformY;
		} ) );
	}

	updateSubnavItemLimits () {
		this.subnavItemLimits = this.subnavData.map( ( snItems, snIndex ) => snItems.map( ( item, index, snItems ) => {
			const heightOfPrevItems = this.subnavItemDimensions[snIndex].reduce( ( height, itemDimensions, itemIndex ) => {
				return itemIndex < index ? height + itemDimensions.height : height;
			}, 0 );

			const heightOfNextItems = this.subnavItemDimensions[snIndex].reduce( ( height, itemDimensions, itemIndex ) => {
				return itemIndex > index ? height + itemDimensions.height : height;
			}, 0 );

			const min = heightOfPrevItems;
			const max = this.subnavDimensions[snIndex].height - heightOfNextItems;

			// get the 2 previous items
			const prevStackItemsToMeasure = snItems.slice( Math.max( index - this.maxStackItemCount, 0 ), index );

			// get the 2 next items
			const nextStackItemsToMeasure = snItems.slice( index + 1,  Math.min( index + this.maxStackItemCount + 1, snItems.length ) );
			
			const stackMin = prevStackItemsToMeasure.reduce( ( height, snItem ) => height + this.subnavItemDimensions[snIndex][snItem.index].height, 0 );
			const stackMax = this.subnavDimensions[snIndex].height - nextStackItemsToMeasure.reduce( ( height, snItem ) => height + this.subnavItemDimensions[snIndex][snItem.index].height, 0 );

			return { min, max, stackMin, stackMax };
		} ) );
	}

	updateSubnavItemTransforms () {
		const clampedSubnavItemPositions = this.subNavItemPositions.map( ( itemPositions, snIndex ) => itemPositions.map( ( pos, index ) => {
			return clamp( pos, this.subnavItemLimits[snIndex][index].min, this.subnavItemLimits[snIndex][index].max );
		} ) );

		// index of last item at the top and first item at the bottom
		const stackBorderIndices = clampedSubnavItemPositions
			.map( ( itemPositions, snIndex ) => itemPositions.reduce( ( borderIndices, clampedPos, index ) => {				
				const limit = this.subnavItemLimits[snIndex][index];
				
				const pos = this.subNavItemPositions[snIndex][index];

				const prevPos = clampedSubnavItemPositions[snIndex][index - 1];
				const nextPos = clampedSubnavItemPositions[snIndex][index + 1];

				const prevLimit = this.subnavItemLimits[snIndex][index - 1];
				const nextLimit = this.subnavItemLimits[snIndex][index + 1];

				const stackClampedPos = clamp(
					this.subNavItemPositions[snIndex][index],
					this.subnavItemLimits[snIndex][index].stackMin,
					this.subnavItemLimits[snIndex][index].stackMax
				);

				const maxIndex = this.subnavItemLimits[snIndex].length - 1;

				const stackClampedPrevPos = index > 0 ? clamp(
					this.subNavItemPositions[snIndex][index - 1],
					this.subnavItemLimits[snIndex][index - 1].stackMin,
					this.subnavItemLimits[snIndex][index - 1].stackMax
				) : clampedPos;

				// const stackClampedNextPos = clamp(
				// 	this.subNavItemPositions[snIndex][index + 1],
				// 	this.subnavItemLimits[snIndex][index + 1].stackMin,
				// 	this.subnavItemLimits[snIndex][index + 1].stackMax
				// );

				if (
					index < itemPositions.length - 1 &&
					stackClampedPos <= limit.stackMin &&
					nextPos > nextLimit.stackMin
				) {
					borderIndices.top = index;
				}

				if (
					index > 0 &&
					pos >= limit.stackMax &&
					stackClampedPrevPos < prevLimit.stackMax
				) {
					borderIndices.bottom = index;
				}
				
				return borderIndices;
			}, { top: -1, bottom: Infinity } ) )

		stackBorderIndices
			.map( ( borderIndices, snIndex ) => {
				// FIX FOR EDGE CASES
				const stackHeight = this.subnavItemDimensions[snIndex].reduce( ( height, dim ) => height + dim.height, 0 );
				const firstItemHeight = this.subnavItemDimensions[snIndex].length ? this.subnavItemDimensions[snIndex][0].height : 0;

				if ( this.maxStackItemCount * 2 * firstItemHeight > this.subnavDimensions[snIndex].height ) {
					const overCount = this.subNavItemPositions[snIndex].filter( pos => pos + this.subnavDimensions[snIndex].y > 0 ).length;

					if ( overCount >= this.subnavData[snIndex].length / 2 ) {
						borderIndices.top = 0;
						borderIndices.bottom = 1;
					} else {
						borderIndices.top = this.subnavData[snIndex].length - 2;
						borderIndices.bottom = this.subnavData[snIndex].length - 1;
					}
				}
								
				if ( borderIndices.top === -1 && clampedSubnavItemPositions[snIndex].length ) {
					const firstPos = clampedSubnavItemPositions[snIndex][0];
					const firstMax = this.subnavItemLimits[snIndex][0].max;
					const firstMin = this.subnavItemLimits[snIndex][0].min;

					const lastIndex = clampedSubnavItemPositions[snIndex].length - 1;
					const lastPos = clampedSubnavItemPositions[snIndex][lastIndex];
					const lastMin = this.subnavItemLimits[snIndex][lastIndex].min;
					const lastMax = this.subnavItemLimits[snIndex][lastIndex].stackMax;
					
					if ( this.scrollPos + firstMax - stackHeight <= 0 ) {
						borderIndices.top = 0;
						borderIndices.bottom = 1;
					} else {
						if ( lastPos <= lastMin ) {
							borderIndices.top = lastIndex;
						}
					}

					return borderIndices;

				} else {
					if ( borderIndices.bottom === Infinity ) {
						borderIndices.bottom = 1;
					}

					return borderIndices;
				}
			} );

		// index of last item at the top and first item at the bottom
		// currently: if moving items crosses 50% height, the indices change

		const stackItemIndices = clampedSubnavItemPositions
			.map( ( itemPositions, snIndex ) => itemPositions.reduce( ( indices, clampedPos, index ) => {
				if ( index <= stackBorderIndices[snIndex].top ) {
					indices.top.push( index );
				}

				if ( index >= stackBorderIndices[snIndex].bottom ) {
					indices.bottom.push( index );
				}

				return indices;
			}, { top: [ ], bottom: [ ] } ) );


		const minLimits = this.subnavItemLimits.map( ( snLimits, snIndex ) => snLimits.map( limit => limit.min ) );
		const maxLimits = this.subnavItemLimits.map( ( snLimits, snIndex ) => snLimits.map( limit => limit.max ) );

		stackItemIndices.forEach( ( snIndices, snIndex ) => {
			const snItems = this.subnavData[snIndex];
			
			if ( snIndices.top.length > this.maxStackItemCount ) {
				const lastIndexInStack = snIndices.top[snIndices.top.length - 1];
								
				// if top stack is higher than it oughtta be
				// we need to move all items to the top by this amount
				// we need to interpolate the amount we move to top			
				
				const visibleStackItems = snItems.slice( Math.max( lastIndexInStack - this.maxStackItemCount, 0 ), lastIndexInStack );
				const visibleStackHeight = visibleStackItems.reduce( ( height, snItem ) => height + this.subnavItemDimensions[snIndex][snItem.index].height, 0 );
				
				const prevItems = snItems.slice( 0, lastIndexInStack );
				const prevItemsHeight = prevItems.reduce( ( height, snItem ) => height + this.subnavItemDimensions[snIndex][snItem.index].height, 0 );

				const prevItemsIncludingLast = snItems.slice( 0, lastIndexInStack + 1 );
				const prevItemsIncludingLastHeight = prevItemsIncludingLast.reduce( ( height, snItem ) => height + this.subnavItemDimensions[snIndex][snItem.index].height, 0 );

				const prevItemDimensions = this.subnavItemDimensions[snIndex][lastIndexInStack - 1];
				const prevItemHeight = prevItemDimensions ? prevItemDimensions.height : 0;

				const lastItemInStackPosition = this.subNavItemPositions[snIndex][lastIndexInStack];

				const min = prevItemsHeight;
				const max = prevItemsIncludingLastHeight;

				const moveToTopAmount = mapRange( lastItemInStackPosition, prevItemHeight, visibleStackHeight, max, min, true ) - visibleStackHeight;

				// move the topmost limit by this amount (move the topmost item by this mouch)
				minLimits[snIndex] = minLimits[snIndex].map( limit => limit - moveToTopAmount );
			}

			if ( snIndices.bottom.length ) {
				const firstIndexInStack = snIndices.bottom[0];

				const visibleStackItems = snItems.slice( firstIndexInStack, Math.min( firstIndexInStack + this.maxStackItemCount, snItems.length ) );
				const visibleStackHeight = visibleStackItems.reduce( ( height, snItem ) => height + this.subnavItemDimensions[snIndex][snItem.index].height, 0 );

				
				const nextItems = snItems.slice( firstIndexInStack + 1, snItems.length );
				const nextItemsHeight = nextItems.reduce( ( height, snItem ) => height + this.subnavItemDimensions[snIndex][snItem.index].height, 0 );				

				const nextItemsIncludingFirst = snItems.slice( firstIndexInStack, snItems.length );
				const nextItemsIncludingFirstHeight = nextItemsIncludingFirst.reduce( ( height, snItem ) => height + this.subnavItemDimensions[snIndex][snItem.index].height, 0 );

				const nextItemDimensions = this.subnavItemDimensions[snIndex][firstIndexInStack + 1];
				const nextItemHeight = nextItemDimensions ? nextItemDimensions.height : 0;

				const firstItemInStackPosition = this.subNavItemPositions[snIndex][firstIndexInStack];
				const firstItemDimensions = this.subnavItemDimensions[snIndex][firstIndexInStack];

				const subnavHeight = this.subnavDimensions[snIndex].height;

				const min = nextItemsHeight;
				const max = nextItemsIncludingFirstHeight;

				const moveToBottomAmount = mapRange( firstItemInStackPosition, subnavHeight - nextItemHeight, subnavHeight - visibleStackHeight, max, min, true ) - visibleStackHeight - firstItemDimensions.height;

				maxLimits[snIndex] = maxLimits[snIndex].map( limit => limit + moveToBottomAmount );
			}
		} );

		this.subNavItemTransforms = this.subNavItemPositions.map( ( snPos, snIndex ) => snPos.map( ( pos, index ) => {
			return clamp( pos, minLimits[snIndex][index], maxLimits[snIndex][index], true );
		} ) );
	}

	render () {
		this.renderNavItems();
		this.renderSubNavs();
	}

	renderNavItems () {
		this.navItemEls.forEach( ( navItemEl, index ) => {
			const y = this.navItemTransforms[index];
			navItemEl.style.transform = 'translateY(' + y + 'px)';
		} );
	}

	renderSubNavs () {
		this.subnavEls.forEach( ( subnavEl, snIndex ) => {
			subnavEl.style.height = this.subnavDimensions[snIndex].height + 'px';

			this.subnavItemEls[snIndex].forEach( ( subnavItemEl, index ) => {
				const translateY = this.subNavItemTransforms[snIndex][index];

				subnavItemEl.style.transform = 'translateY(' + translateY + 'px)';
			} );
		} );
	}

	renderSubNavItems () {
	
	}
}

function getElDimensions ( el ) {
	return {
		width: el.offsetWidth,
		height: el.offsetHeight,
		x: el.offsetLeft,
		y: el.offsetTop,
		id: el.id
	};
}

function getBBElDimensions ( el ) {
	const bb = el.getBoundingClientRect();
	
	return {
		width: bb.width,
		height: bb.height,
		x: el.offsetLeft,
		y: el.offsetTop,
		id: el.id
	};
}

function getScrollPos ( el ) {
	el = el || document.documentElement || document.body;

	return el.scrollTop
}
