<div
	class="carousel"
	class:is-dragging="{isDragging}"
>
	<ul
		class="carousel-slides"
		bind:this="{containerEl}"
	>
	{#each items as item, index}
		<li
			class="carousel-slide"
			bind:this="{embedWrapper}"
		>
			<div
				class="carousel-slide-content"
				class:is-transitioning="{isTransitioning}"
				style="
					transform: translateX({slideContentTranslateX(index)});
				"
			>
				<EmbedImage
					bind:projectId="{projectId}"
					bind:linkEl="{item}"
					bind:embedId="{embedIds[index]}"
					isInCarousel="{true}"
				></EmbedImage>
			</div>
		</li>
	<!-- <div class="carousel-item">1</div> -->
	{/each}
	</ul>
	{#if items.length > 1}
	<nav class="carousel-prevnext-nav">
		<button
			class="btn prev-btn"
			on:click="{prev}"
			title="Previous"
			disabled="{isPrevDisabled}"
		>⇠</button>
		<button
			class="btn next-btn"
			on:click="{next}"
			title="Next"
			disabled="{isNextDisabled}"
		>⇢</button>
	</nav>
	{/if}
</div>

<style>
.carousel {
	position: relative;
	user-select: none;
}

.carousel-slide {
	display: block;
	width: 100%;
	height: 100%;
	overflow: hidden;
}

.carousel-slide-content {
	transform-origin: 50% 50%;
}

.carousel-slide-content.is-transitioning  {
	/* transition: all 0.3s ease; */
}

.carousel-prevnext-nav > .btn {
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	background-color: #000;
	background-color: var(--text-color);
	color: #fff;
	color: var(--background-color);
	transition: all 0.2s ease;
	z-index: 3;
	padding: 8px 10px;
	outline: none;
}

.carousel-prevnext-nav .btn[disabled] {
	cursor: none;
}

.carousel-prevnext-nav .prev-btn {
	left: 0;
	padding-left: 40px;
	transform: translateX(-30px) translateY(-50%);
}

.carousel-prevnext-nav .prev-btn:hover {
	transform: translateX(0) translateY(-50%);
}

.carousel-prevnext-nav .prev-btn[disabled] {
	transform: translateX(-100%) translateY(-50%);
}

.carousel-prevnext-nav .next-btn {
	right: 0;
	padding-right: 40px;
	transform: translateX(30px) translateY(-50%);
}

.carousel-prevnext-nav .next-btn:hover {
	transform: translateX(0) translateY(-50%);
}

.carousel-prevnext-nav .next-btn[disabled] {
	transform: translateX(100%) translateY(-50%);
}

</style>

<script>
// normal carousel
// 1. create slides:
// one 
// physics-movement
// physics-transform
import EmbedImage from './EmbedImage.svelte';
import { onMount, tick } from 'svelte';
import Siema from './lib/siema.js';
import { mapRange } from './util/math.js';
import { requestEmbed } from './stores/embed.js';
import { activeProjectIds, visibleProjectIds } from './stores/projects.js';

export let items = [ ];
export let perPage = 1;
export let loop = false;
export let autoplay = 0;
export let projectId = null;
export let slideContentScale = 1.5;

let carouselInstance = null;
let autoplayId;
let containerEl;
let embedWrappers = [ ];
let embedWrapper = null;
let currentSlideIndex = 0;
let carouselTanslateX = 0;
let wrapperWidth = 0;
let isTransitioning = false;
let isEmbedInCarousel = true;
let isDragging = false;

$ : isProjectOpen = $activeProjectIds.includes( projectId );
$ : isProjectVisible = $visibleProjectIds.includes( projectId );
$ : canShow = projectId ? isProjectOpen && isProjectVisible : true;

$ : embedIds = items.map( ( item, index ) => projectId + '_' + index + '_carousel' );
$ : embedSettings = items.map( ( item, index ) => getSettings( item ) );
$ : lastIndex = items.length - 1;
$ : isPrevDisabled = currentSlideIndex <= 0;
$ : isNextDisabled = currentSlideIndex >= lastIndex;
$ : duration = 100 + mapRange( window.innerWidth, 300, 2400, 0, 800 );
$ : slideContentTranslateX = ( slideIndex ) => {
	// scale: 1.2 => min -10%, max: 10%
	const min = -( ( slideContentScale - 1 ) / 2 ) * 100;
	const max = ( ( slideContentScale - 1 ) / 2 ) * 100;
	const slideLeft = wrapperWidth * slideIndex;
	const minTranslate = -slideLeft - wrapperWidth;
	const maxTranslate = -slideLeft + wrapperWidth;
	const translateX = mapRange( carouselTanslateX, minTranslate, maxTranslate, max, min, true );

	return translateX + '%';
};

$ : {
	if ( canShow && ! carouselInstance ) {
		const itemCount = items.length;
		
		carouselInstance = new Siema( {
			selector: containerEl,
			perPage: Math.min( itemCount, perPage ),
			loop,
			onChange: handleChange,
			onUpdate: handleUpdate,
			onTransitionChange: handleTransitionChange,
			onDragChange: handleDragChange,
			duration
		} );

		loadSlide(0);
		loadNextSlide();
	}
}

onMount( () => {
	if ( items.length ) {
		
		// const childrenCountBefore = Array.from( containerEl.children ).length;

		return () => {
			if ( autoplay > 0 ) {
				clearInterval( autoplayId );
			}

			if ( carouselInstance ) {
				carouselInstance.destroy();
			}
		}
	}
} );

function prev () {
	carouselInstance.prev();
}
	
function next () {
	carouselInstance.next();
}

function goTo (index) {
	carouselInstance.goTo( index );
}

function handleChange () {
	currentSlideIndex = carouselInstance.currentSlide;
	loadNextSlide();
}

function handleUpdate ( { x, width } ) {
	wrapperWidth = width;
	carouselTanslateX = x;
}

function loadNextSlide () {
	const nextSlideIndex = currentSlideIndex + 1;
	loadSlide( nextSlideIndex );
}

function loadSlide ( slideIndex ) {
	const embedId = embedIds[slideIndex];

	if ( embedId ) {
		requestEmbed( embedId );
	}
}

function handleTransitionChange ( event ) {
	isTransitioning = event.isTransitioning;
}

function handleDragChange ( dragging ) {
	isDragging = dragging;
}

function getSettings ( linkEl ) {
	return Object.assign( { }, linkEl.dataset );
}

</script>