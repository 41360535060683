export function getScrollPos ( el ) {
	el = el || document.documentElement || document.body;

	return el.scrollTop
}

export function getBBElDimensions ( el ) {
	const bb = el.getBoundingClientRect();
	
	return {
		width: bb.width,
		height: bb.height,
		x: el.offsetLeft,
		y: el.offsetTop,
		id: el.id
	};
}

export function getElDimensions ( el ) {
	return {
		width: el.offsetWidth,
		height: el.offsetHeight,
		x: el.offsetLeft,
		y: el.offsetTop,
		id: el.id
	};
}